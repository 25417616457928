<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item link v-for="(item, i) in items" :key="i" :to="{ name: item.name }">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>TokenFlow</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-select
          label="Token"
          v-if="isLogin"
          v-model="selectedToken"
          :items="tokenItems"
          item-text="text"
          item-value="value"
          dense
          outlined
          class="mt-5"
        ></v-select>
      </v-col>
      <v-btn icon @click="logout" v-if="isLogin">
        <v-icon>exit_to_app</v-icon>
      </v-btn>
      <form method="POST" action="/logout" id="logout" class="d-none">
        <input type="hidden" name="_token" value="" />
      </form>
    </v-app-bar>

    <v-main>
      <v-container fluid><router-view></router-view></v-container>
    </v-main>
  </v-app>
</template>

<script>
import api from './utils/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app',
  data: () => ({
    drawer: false,
    activeIndex: '',
    selectedToken: '',
    items: [
      {
        icon: 'mdi-view-dashboard',
        name: 'dashboard',
        title: 'Dashboard',
        path: '/dashbard',
      },
      {
        icon: 'mdi-bitcoin',
        name: 'token',
        title: 'Token',
        path: '/token',
      },
      {
        icon: 'mdi-account-multiple',
        name: 'account',
        title: 'Account',
        path: '/account',
      },
      {
        icon: 'mdi-arrow-collapse-right',
        name: 'transfer',
        title: 'Transfer',
        path: '/transfer',
      },
      {
        icon: 'mdi-file-document-multiple-outline',
        name: 'transaction',
        title: 'Transaction',
        path: '/transaction',
      },
      {
        icon: 'mdi-alert-circle-outline',
        name: 'alert',
        title: 'Alert',
        path: '/alert',
      },
      {
        icon: 'mdi-download-box-outline',
        name: 'csv',
        title: 'CSV',
        path: '/csv',
      },
      {
        icon: 'mdi-account-outline',
        name: 'password',
        title: 'Password',
        path: '/password',
      },
    ],
  }),
  methods: {
    ...mapActions(['doLogout', 'loadTokenList', 'selectToken']),
    logout() {
      api.post('/api/auth/logout').then((result) => {
        if (result.data.result == 'ok') {
          this.doLogout();
        }
      });
    },
  },
  watch: {
    selectedToken() {
      this.selectToken(this.selectedToken);
    },
    isLogin() {
      if (this.isLogin) {
        this.loadTokenList();
      }
    },
  },
  computed: mapGetters(['isLogin', 'tokenItems']),
};
</script>
