<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadcrumbs" large></v-breadcrumbs>
    <div v-if="!selectedToken">
      <div v-for="stat in allStats" :key="stat.tokenSymbol">
        <v-row class="pl-3">
          <h4>{{ stat.tokenSymbol }}</h4>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <stats-card>
              <div class="icon-big text-center" :class="`icon-warning`" slot="header">
                <i class="ti-timer"></i>
              </div>
              <div class="numbers" slot="content">
                <p>Last 1h Transfer</p>
                {{ stat.summary.oneHourCount }}
              </div>
            </stats-card>
          </v-col>
          <v-col cols="12" md="3">
            <stats-card>
              <div class="icon-big text-center" :class="`icon-danger`" slot="header">
                <i class="ti-plus"></i>
              </div>
              <div class="numbers" slot="content">
                <p>Last 1h Transfer Amount</p>
                {{ formatNumber(stat.summary.oneHourAmount) }}
              </div>
            </stats-card>
          </v-col>
          <v-col cols="12" md="3">
            <stats-card>
              <div class="icon-big text-center" :class="`icon-success`" slot="header">
                <i class="ti-time"></i>
              </div>
              <div class="numbers" slot="content">
                <p>Last 24h Transfer</p>
                {{ stat.summary.oneDayCount }}
              </div>
            </stats-card>
          </v-col>
          <v-col cols="12" md="3">
            <stats-card>
              <div class="icon-big text-center" :class="`icon-info`" slot="header">
                <i class="ti-stats-up"></i>
              </div>
              <div class="numbers" slot="content">
                <p>Last 24h Transfer Amount</p>
                {{ formatNumber(stat.summary.oneDayAmount) }}
              </div>
            </stats-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="selectedToken && stats">
      <v-row>
        <v-col cols="12" md="3">
          <stats-card>
            <div class="icon-big text-center" :class="`icon-warning`" slot="header">
              <i class="ti-timer"></i>
            </div>
            <div class="numbers" slot="content">
              <p>Last 1h Transfer</p>
              {{ stats.summary.oneHourCount }}
            </div>
          </stats-card>
        </v-col>
        <v-col cols="12" md="3">
          <stats-card>
            <div class="icon-big text-center" :class="`icon-danger`" slot="header">
              <i class="ti-plus"></i>
            </div>
            <div class="numbers" slot="content">
              <p>Last 1h Transfer Amount</p>
              {{ formatNumber(stats.summary.oneHourAmount) }}
            </div>
          </stats-card>
        </v-col>
        <v-col cols="12" md="3">
          <stats-card>
            <div class="icon-big text-center" :class="`icon-success`" slot="header">
              <i class="ti-time"></i>
            </div>
            <div class="numbers" slot="content">
              <p>Last 24h Transfer</p>
              {{ stats.summary.oneDayCount }}
            </div>
          </stats-card>
        </v-col>
        <v-col cols="12" md="3">
          <stats-card>
            <div class="icon-big text-center" :class="`icon-info`" slot="header">
              <i class="ti-stats-up"></i>
            </div>
            <div class="numbers" slot="content">
              <p>Last 24h Transfer Amount</p>
              {{ formatNumber(stats.summary.oneDayAmount) }}
            </div>
          </stats-card>
        </v-col>
      </v-row>
      <div class="row">
        <div class="col-md-4 col-12 mt-40" v-if="stats.supply.total > 0">
          <div class="smallChart">
            <doughnut-chart :chartData="foundation"></doughnut-chart>
            <v-btn small color="primary" @click="changeColor">color</v-btn>
          </div>
        </div>
        <div class="col-md-4 col-12 mt-40" v-if="stats.supply.total > 0">
          데이터
          <v-data-table class="mt-40" :items="foundation.table" :headers="shareHeaders" hide-default-footer hide-default-header>
            <template v-slot:[`item.name`]="{ item }"> {{ item.name }} </template>
            <template v-slot:[`item.percent`]="{ item }"> {{ item.percent }} % </template>
            <template v-slot:[`item.value`]="{ item }"> {{ item.value }} {{ selectedToken }} </template>
          </v-data-table>
        </div>
        <div class="col-md-1 col-12 mt-40" v-if="stats.supply.total > 0"></div>
        <div class="col-md-3 col-12 mt-40" v-if="stats.supply.total > 0">
          거래소별 수량
          <v-data-table class="mt-40" :items="exchangeList" :headers="exchangeHeaders" hide-default-footer hide-default-header>
            <template v-slot:[`item.name`]="{ item }"> {{ item.name }} </template>
            <template v-slot:[`item.value`]="{ item }"> {{ item.value.toLocaleString('en') }} {{ selectedToken }} </template>
          </v-data-table>
        </div>
        <div class="col-md-12 col-12 mt-40">
          <div class="smallChart2">
            <line-chart :chartData="oneDayData" :options="options"></line-chart>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<style>
.statsCardHeader .numbers p {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.statsCardHeader .numbers {
  font-size: 1.5rem;
}

.smallChart {
  max-width: 450px;
  margin: 0 auto;
}

.smallChart2 {
  margin: 0 auto;
  max-height: 400px;
  height: 400px;
}

.smallChart p {
  text-align: center;
}
</style>

<script>
import api from '../utils/api';
import StatsCard from './components/StatsCard';
import DoughnutChart from './components/DoughnutChart';
import LineChart from './components/LineChart';
import { mapGetters } from 'vuex';
import randomColor from 'randomcolor';
import '../assets/sass/paper-dashboard.scss';
import '../assets/css/themify-icons.css';
import exchangeNames from '../utils/exchangeNames';

export default {
  components: {
    StatsCard,
    DoughnutChart,
    LineChart,
  },
  data: () => ({
    breadcrumbs: [{ text: 'Dashboard', disabled: false }],
    foundation: {
      labels: [],
      datasets: [],
      total: 0,
      table: [],
    },
    shareHeaders: [
      { text: 'Name', sortable: false, align: 'center', value: 'name' },
      { text: 'Percent', sortable: false, align: 'right', value: 'percent' },
      { text: 'Value', sortable: false, align: 'right', value: 'value' },
    ],
    exchangeHeaders: [
      { text: 'Name', sortable: false, align: 'center', value: 'name' },
      { text: 'Value', sortable: false, align: 'right', value: 'value' },
    ],
    options: {
      repsonsive: false,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
    },
    allStats: [],
    stats: null,
    foundation: null,
    exchangeList: null,
    oneDayData: null,
  }),
  watch: {
    selectedToken() {
      this.retrieveData();
    },
  },
  methods: {
    formatNumber(number) {
      return number.toLocaleString('en');
    },
    changeColor() {
      this.foundation = {
        labels: this.foundation.labels,
        datasets: [{ backgroundColor: [randomColor(), randomColor(), randomColor(), randomColor()], data: this.foundation.datasets[0].data }],
        table: this.foundation.table,
      };
    },
    retrieveData() {
      const params = { tokenSymbol: this.selectedToken };
      api.get('/api/dashboard', { params }).then((result) => {
        if (result.status === 200) {
          if (!this.selectedToken) {
            this.allStats = result.data;
            this.stats = null;
          } else {
            this.stats = result.data;
            this.foundation = {
              labels: ['회사컨트롤', '거래소', 'unlock', '블랙리스트'],
              datasets: [
                {
                  backgroundColor: [randomColor(), randomColor(), randomColor(), randomColor()],
                  data: [this.stats.supply.foundation, this.stats.supply.exchange, this.stats.supply.unlock, this.stats.supply.lock],
                },
              ],
              table: [
                {
                  name: '전체',
                  percent: 100,
                  value: this.stats.supply.total,
                },
                {
                  name: '회사컨트롤',
                  percent: this.stats.supply.total == 0 ? 0 : ((this.stats.supply.foundation / this.stats.supply.total) * 100).toFixed(),
                  value: this.stats.supply.foundation.toLocaleString('en'),
                },
                {
                  name: '거래소',
                  percent: this.stats.supply.total == 0 ? 0 : ((this.stats.supply.exchange / this.stats.supply.total) * 100).toFixed(),
                  value: this.stats.supply.exchange.toLocaleString('en'),
                },
                {
                  name: 'unlock',
                  percent: this.stats.supply.total == 0 ? 0 : ((this.stats.supply.unlock / this.stats.supply.total) * 100).toFixed(),
                  value: this.stats.supply.unlock.toLocaleString('en'),
                },
                {
                  name: 'black',
                  percent: this.stats.supply.total == 0 ? 0 : ((this.stats.supply.lock / this.stats.supply.total) * 100).toFixed(),
                  value: this.stats.supply.lock.toLocaleString('en'),
                },
              ],
            };
            this.exchangeList = [];
            const keys = Object.keys(this.stats.exchange).sort((a, b) => {
              if (this.stats.exchange[a] == this.stats.exchange[b]) return 0;
              return this.stats.exchange[a] > this.stats.exchange[b] ? -1 : 1;
            });
            for (let i = 0; i < keys.length; i++) {
              this.exchangeList.push({ name: exchangeNames[keys[i]], value: this.stats.exchange[keys[i]] });
            }

            const counts = [];
            const amounts = [];
            const labels = [];
            const oneDayKeys = Object.keys(this.stats.summary.oneDayList).sort();
            for (let i = 0; i < oneDayKeys.length; i++) {
              const key = oneDayKeys[i];

              labels.push(new Date(parseInt(key, 10)).toISOString().slice(0, 13).replace(/T/, ' '));
              counts.push(this.stats.summary.oneDayList[key].count);
              amounts.push(this.stats.summary.oneDayList[key].amount);
            }
            this.oneDayData = {
              labels,
              datasets: [
                {
                  label: '횟수',
                  backgroundColor: [randomColor()],
                  data: counts,
                  yAxisID: 'y-axis-1',
                },
                { label: '값', backgroundColor: [randomColor()], data: amounts, yAxisID: 'y-axis-2' },
              ],
            };
          }
        }
      });
    },
  },
  computed: mapGetters(['selectedToken']),
  created() {
    this.retrieveData();
  },
};
</script>
