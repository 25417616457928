const exchangeNames = {
  upbit: '업비트',
  upxide: '업사이드',
  bihuex: '비후엑스',
  fcoin: 'Fcoin',
  bithumb: '빗썸',
  nexybit: '넥시빗',
  coinone: '코인원',
  coinbene: '코인베네',
  bitforex: '비트포렉스',
  smegwallet: 'SMEGWALLET',
  boboo: 'Boboo',
  dragonex: 'DragonEx',
  mxc: 'MXC',
  okex: 'OKEx',
  bithumbglobal: '빗썸글로벌',
  hotbit: '핫빗',
  latoken: '라토큰',
  coinbit: '코인빗',
  coinzest: '코인제스트',
  foblgate: '포블게이트',
  digifinex: '디지파이넥스',
  melonex: 'MelonEx',
  // not used now
  binance: '바이낸스',
  coinbase: '코인베이스',
  huobi: '후오비',
  liquid: 'Liquid',
  korbit: '코빗',
  coinbit: '코인빗',
  hitbtc: 'HitBTC',
  gopax: '고팍스',
  hoocom: 'Hoo.com',
  dcoin: 'Dcoin',
  bittrex: 'BITTREX',
  lbank: 'LBANK',
  gateio: 'Gate.io',
};
module.exports = exchangeNames;
