import axios from 'axios';
import store from '../store/index';

const api = axios.create();

api.interceptors.response.use(
  (response) => {
    store.commit('LOGIN');
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('doLogout');
      } else if (error.response.status === 403) {
        store.dispatch('doLogout'); // not a super user or csrf error
        error.response.message = 'Not have enough permission';
      }
      error.response.error = true;
      return Promise.resolve(error.response);
    }
    return Promise.resolve({ error: true, message: 'Network Error', data: '' });
  }
);

export default api;
