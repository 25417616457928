import router from '../../router';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

const state = {
  isLogin: false,
};

const getters = {
  isLogin: (state) => state.isLogin,
};

const actions = {
  doLogin({ commit }) {
    router.push('dashboard');
    commit(LOGIN);
  },
  doLogout({ commit }) {
    router.push('login');
    commit(LOGOUT);
  },
};

const mutations = {
  [LOGIN](state) {
    state.isLogin = true;
  },
  [LOGOUT](state) {
    state.isLogin = false;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
