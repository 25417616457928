import api from '../../utils/api';

const LOAD_TOKEN = 'LOAD_TOKEN';
const SELECT_TOKEN = 'SELECT_TOKEN';

const state = {
  tokenList: [],
  selectedToken: '',
};

const getters = {
  // need many types of getters
  tokenList: (state) => state.tokenList,
  tokenItems: (state) => {
    const items =
      state.tokenList && state.tokenList.map
        ? state.tokenList.map((token) => {
            return { text: token.symbol, value: token.symbol };
          })
        : [];
    items.unshift({ text: 'All', value: '' });
    return items;
  },
  tokenMinItems: (state) => {
    const items =
      state.tokenList && state.tokenList.map
        ? state.tokenList
            .filter((token) => {
              return token.tokenType === 'ERC20';
            })
            .map((token) => {
              return { text: token.symbol, value: token.symbol };
            })
        : [];
    return items;
  },
  selectedToken: (state) => state.selectedToken,
};

const actions = {
  loadTokenList({ commit }) {
    api.get('/api/token/list').then((result) => {
      if (result.data) {
        commit(LOAD_TOKEN, { tokenList: result.data });
      }
    });
  },
  selectToken({ commit }, selectedToken) {
    commit(SELECT_TOKEN, { selectedToken });
  },
};

// we need to use payload for vue dev tool
const mutations = {
  [LOAD_TOKEN](state, payload) {
    state.tokenList = payload.tokenList;
  },
  [SELECT_TOKEN](state, payload) {
    state.selectedToken = payload.selectedToken;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
