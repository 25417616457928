import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    { path: '/', name: 'root', component: Dashboard },
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    {
      path: '/login',
      name: 'login',
      component: function () {
        return import(/* webpackChunkName: "login" */ '../views/Login.vue');
      },
    },
    {
      path: '/token',
      name: 'token',
      component: function () {
        return import(/* webpackChunkName: "token" */ '../views/Token.vue');
      },
    },
    {
      path: '/account',
      name: 'account',
      component: function () {
        return import(/* webpackChunkName: "account" */ '../views/Account.vue');
      },
    },
    {
      path: '/transfer',
      name: 'transfer',
      component: function () {
        return import(/* webpackChunkName: "transfer" */ '../views/Transfer.vue');
      },
    },
    {
      path: '/transaction',
      name: 'transaction',
      component: function () {
        return import(/* webpackChunkName: "transaction" */ '../views/Transaction.vue');
      },
    },
    {
      path: '/csv',
      name: 'csv',
      component: function () {
        return import(/* webpackChunkName: "csv" */ '../views/Csv.vue');
      },
    },
    {
      path: '/alert',
      name: 'alert',
      component: function () {
        return import(/* webpackChunkName: "alert" */ '../views/Alert.vue');
      },
    },
    {
      path: '/password',
      name: 'password',
      component: function () {
        return import(/* webpackChunkName: "password" */ '../views/Password.vue');
      },
    },
  ],
});

export default router;
